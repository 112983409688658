import React, {useEffect, useState} from 'react';
import {Select, Tabs, Typography} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {ReportContent} from '../modules/Report';
import {StatisticRepository} from '../modules/Report/StatisticRepository';
import {SchoolService} from '../modules/Schools';
import {setSchool} from '../redux/actions/user';

const {Text} = Typography;

const TabsHeader = () => {
  const dispatch = useDispatch();

  const teacher = useSelector((state: any) => state.user?.teacher);

  const fullReport = useSelector((state: any) => state.fullReport.fullReport);
  const classes = useSelector((state: any) => state.user?.classes);
  const school = useSelector((state: any) => state.user?.school);
  const {schoolId} = useParams<{schoolId: string}>();

  const [months, setMonths] = useState<string[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<{
    month: string;
    index: number;
  } | null>(null);
  const [selectedClassId, setSelectedClassId] = useState<string>('');

  const mode = 'top';
  const schoolService = new SchoolService();
  const statisticRepository = new StatisticRepository();

  // Set the first class as default if available.
  useEffect(() => {
    if (classes && classes.length > 0 && !selectedClassId) {
      setSelectedClassId(classes[0].id);
    }
  }, [classes, selectedClassId]);

  const generateMonthsTabs = (classId: string) => {
    if (months.length === 0) return [];
    return months.map((month, index) => ({
      label: (
        <Text
          style={{
            display: 'block',
            fontFamily: 'Roboto',
            color: '#999999',
            fontSize: 16,
          }}>
          {moment(month, 'YYYY-MM').format('MMM YYYY')}
        </Text>
      ),
      key: month,
      children: (
        <ReportContent
          classId={classId}
          selectedMonth={month}
          monthIndex={index}
        />
      ),
    }));
  };

  const fetchThePrevReportsMonthsByYears = (reportData: any) => {
    let monthsArr: string[] = [];
    const startOfYear = moment().startOf('year');
    const currentMonth = moment();
    while (startOfYear <= currentMonth) {
      monthsArr?.push(startOfYear.format('YYYY-MM'));
      startOfYear.add(1, 'month');
    }
    if (reportData?.monthlyReports && reportData.monthlyReports.length > 0) {
      reportData.monthlyReports.forEach((report: any) => {
        monthsArr?.push(moment(report.month).format('YYYY-MM'));
      });
    }
    // Remove duplicates and sort chronologically
    monthsArr = Array.from(new Set(monthsArr));
    monthsArr?.sort((a, b) => moment(a, 'YYYY-MM').diff(moment(b, 'YYYY-MM')));
    return monthsArr;
  };

  const fetchThePrevReportsMonths = (reportData: any) => {
    const monthsArr = fetchThePrevReportsMonthsByYears(reportData);
    if (!monthsArr) return;
    const currentMonthStr = moment().format('YYYY-MM');
    setSelectedMonth({
      month: currentMonthStr,
      index: monthsArr.indexOf(currentMonthStr),
    });
    setMonths(monthsArr);
  };

  // Update the months tabs when fullReport changes.
  useEffect(() => {
    fetchThePrevReportsMonths(fullReport);
  }, [fullReport]);

  // Fetch the school if schoolId exists and school is not yet set.
  useEffect(() => {
    if (schoolId && !school) {
      schoolService.getSchoolById(schoolId).then((response: any) => {
        // Use the teacher from Redux instead of passing null.
        dispatch(setSchool(response, teacher, response.classesschool));
      });
    }
  }, [schoolId, school, teacher, dispatch]);

  // Handle month tab changes.
  const handleTabChange = (key: string) => {
    setSelectedMonth({
      month: key,
      index: months.indexOf(key),
    });
  };

  // Fetch new reports when a new class is selected.
  const fetchNewReports = async (id: string) => {
    try {
      const report = await statisticRepository.getFullReport(id);
      fetchThePrevReportsMonths(report);
    } catch (e) {
      console.error('Error fetching report:', e);
    }
  };

  // Trigger report fetching when the selected class changes.
  useEffect(() => {
    if (selectedClassId) {
      const selectedClass = classes?.find(
        (cls: any) => cls.id === selectedClassId,
      );
      if (selectedClass) {
        fetchNewReports(selectedClass.statistic.id);
      }
    }
  }, [selectedClassId, classes]);

  return (
    <div style={{flex: 1}}>
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Select
          value={selectedClassId}
          onChange={(value) => setSelectedClassId(value)}
          showSearch
          filterOption={(input, option: any) =>
            option?.children?.toLowerCase().includes(input.toLowerCase())
          }
          style={{width: '50%', marginTop: '2rem'}}>
          {classes?.map((classSchool: any) => (
            <Select.Option key={classSchool.id} value={classSchool.id}>
              {classSchool.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      {selectedMonth && selectedClassId && (
        <Tabs
          defaultActiveKey={selectedMonth.month}
          centered
          onChange={handleTabChange}
          tabPosition={mode}
          items={generateMonthsTabs(selectedClassId)}
        />
      )}
    </div>
  );
};

export default TabsHeader;
